import React, { useState } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import sampleImage from "../img/products/sample-image.jpg"
import { Link } from "gatsby"

export default function AboutUs() {
  const [productList, setproductList] = useState([
    "DIARIES & JOURNALS",
    "BADGES",
    "LUNCH BOX & BAGS",
    "PENS-PREMIUM & PROMOTIONAL",
    "CARD HOLDERS",
    "CALENDER",
    "JOINEE KIT",
    "ID CARD HOLDER",
  ])
  return (
    <div>
       <Helmet>
        <title>Office Utility - Represent Corporate Giftings</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="Office Utility - One stop solution for all your office needs with a wide range of products." />
        <meta
          name="description"
          content="One stop solution for all your office needs with a wide range of products."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://representgiftings.com/bags" />
        <meta
          property="og:description"
          content="One stop solution for all your office needs with a wide range of products."
        />
      </Helmet>
      <Layout>
        <div className="pageHeader">
          <h1>OFFICE UTILITY</h1>
        </div>
        <div className="container paddingSuper">
          <div className="row">
            {productList.map(product => (
              <div className="col-md-3">
                <div className="productDetailsCard">
                  <img
                    src={
                      "https://representgiftings.com/images/" +
                      product
                        .toLowerCase()
                        .replace("&", "")
                        .replace(" ", "-")
                        .replace(" ", "") +
                      ".jpg"
                    }
                    alt={product}
                  ></img>
                  <h5 className="productDetailstitle">{product}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  )
}
